/**
 * @license
 * Copyright 2019 Google Inc. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import {NamedAttrMap, NamedTensorInfoMap, registerKernel} from '@tensorflow/tfjs-core';
import {TensorInfo} from '@tensorflow/tfjs-core';

import {BackendWasm} from '../backend_wasm';

interface ReshapeInputs extends NamedTensorInfoMap {
  x: TensorInfo;
}

interface ReshapeAttrs extends NamedAttrMap {
  shape: number[];
}

function reshape(
    args: {inputs: ReshapeInputs, attrs: ReshapeAttrs, backend: BackendWasm}) {
  const {inputs: {x}, attrs: {shape}} = args;
  return {dataId: x.dataId, shape, dtype: x.dtype};
}

registerKernel({
  kernelName: 'Reshape',
  backendName: 'wasm',
  kernelFunc: reshape,
});
